import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Circle from "../img/icons/circle.svg";
import CheckCircle from "../img/icons/check-circle.svg";
import withWidth from "@material-ui/core/withWidth";
import Slider from "react-slick";

const PitchListItem = withStyles({
  root: {
    marginBottom: "13px",
    color: "#15362B",
    fontWeight: "bold",
    backgroundColor: "#FFFFFF",
    borderRadius: "7px",
    borderStyle: "solid",
    border: "1px solid transparent",
    boxShadow: "0px 0px 24px rgb(133 195 179 / 15%)",
    "&.Mui-selected": {
      boxShadow: "none",
      backgroundColor: "#fff !important",
      color: "#0C8868",
      cursor: "default",
    },
    "&:hover": {
      backgroundColor: "#E5F2EF",
      color: "#0C8868",
    },
  },
})(ListItem);

function GreenhousePitch(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedPitchListItem, setSelectedPitchListItem] = React.useState(0);
  const { width, listitems, heading, subheading } = props;

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    swipeToSlide: true,
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const changeImage = (index) => {
    setSelectedPitchListItem(index);
  };

  return width == "lg" || width == "xl" ? (
    <div className="pitch">
      <Grid justify="space-between" className="container" container>
        <Grid item sm={6}>
          <div>
            <div className="content">
              <img
                src={listitems[selectedPitchListItem].image.publicURL}
                style={{ width: "800px" }}
              ></img>
            </div>
          </div>
        </Grid>
        <Grid item sm={6} className="pitch-list-container">
          <div className="pitch-list">
            <div>
              <h2 className="fp-title">{heading}</h2>
              <p className="fp-subheading">{subheading}</p>
            </div>
            <List
              component="nav"
              aria-label="main mailbox folders"
              className="list-items"
            >
              {listitems.map((listitem, index) => (
                <PitchListItem
                  selected={index === selectedPitchListItem}
                  className="list-item"
                  key={index}
                  button
                  onClick={() => changeImage(index)}
                >
                  <span className="check">
                    {index === selectedPitchListItem ? (
                      <CheckCircle />
                    ) : (
                      <Circle />
                    )}
                  </span>
                  <ListItemText primary={listitem.listtext} />
                </PitchListItem>
              ))}
            </List>
            {/* <Button style={{ marginTop: "60px" }} className="action-btn">
              Hanna mitt hús
            </Button> */}
          </div>
        </Grid>
      </Grid>
    </div>
  ) : (
    <div className="mobile-pitch">
      <div className="description">
        <h2 className="fp-title">{heading}</h2>
        <p className="fp-subheading">{subheading}</p>
      </div>
      <Slider {...sliderSettings}>
        {listitems.map((listitem, index) => (
          <div
            selected={index === selectedPitchListItem}
            className="list-item"
            key={index}
            onClick={() => changeImage(index)}
          >
            <div className="slider-item-text">
              <CheckCircle /> <h3>{listitem.listtext}</h3>
            </div>
            <img
              src={listitem.image.publicURL}
              className="slider-item-image"
            ></img>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default withWidth()(GreenhousePitch);
