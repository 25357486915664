import React, { useRef } from "react";
import assetFlip from "../img/AssetMynd-flipped.png";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Icon } from "@iconify/react";
import chevronCompactDown from "@iconify/icons-bi/chevron-compact-down";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Button from "@material-ui/core/Button";
import Circle from "../img/icons/circle.svg";
import CheckCircle from "../img/icons/check-circle.svg";
import GreenhousePitch from "../components/GreenhousePitch.js";
import { Link } from "gatsby";

const PitchListItem = withStyles({
  root: {
    marginBottom: "13px",
    color: "#15362B",
    fontWeight: "bold",
    backgroundColor: "#FFFFFF",
    borderRadius: "7px",
    borderStyle: "solid",
    border: "1px solid transparent",
    boxShadow: "0px 0px 24px rgb(133 195 179 / 15%)",
    "&.Mui-selected": {
      boxShadow: "none",
      backgroundColor: "#fff !important",
      color: "#0C8868",
      cursor: "default",
    },
    "&:hover": {
      backgroundColor: "#E5F2EF",
      color: "#0C8868",
    },
  },
})(ListItem);

export const IndexPageTemplate = ({
  selectedPitchListItem,
  changeImage,
  image,
  title,
  pitchvideo,
  pitchcloser,
  pitchimage,
  subheading,
  pitchlist,
  scrollRef,
  executeScroll,
}) => (
  <div className="frontpage">
    <div className=" margin-top-0">
      <div
        className="fp-top-image"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
        }}
      >
        <div className="container">
          <div className="fp-infobox">
            <p className="subtitle">{subheading}</p>
            <h1 className="pagetitle">{title}</h1>
            <Link to="/store">
              <Button className="action-btn">Hanna mitt hús</Button>
            </Link>
          </div>

          <IconButton className="down-arrow" onClick={executeScroll}>
            {" "}
            <Icon
              icon={chevronCompactDown}
              style={{ color: "#dddddd", fontSize: "75px" }}
            />{" "}
          </IconButton>
        </div>
      </div>
    </div>
    {/*<div
    className=" margin-top-0">
    <div
      style={{
        display: 'flex',
        height: '800px',
        lineHeight: '1',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <h2
        className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
        style={{
          marginTop: "20px",
          boxShadow:
            'rgb(134, 134, 134) 0.5rem 0px 0px, rgb(134, 134, 134) -0.5rem 0px 0px',
          backgroundColor: 'rgb(134, 134, 134)',
          color: 'white',
          padding: '0.25em',
        }}
      >
        {pitchvideo.heading}
      </h2>
      <h3
        className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
        style={{
          boxShadow:
            'rgb(221,221,221) 0.5rem 0px 0px, rgb(221,221,221) -0.5rem 0px 0px',
          backgroundColor: 'rgb(221,221,221)',
          marginTop: "0px",
          color: 'white',
          lineHeight: '1',
          padding: '0.25em',
        }}
      >
        {pitchvideo.subheading1}
      </h3>
      <h3
        className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
        style={{
          boxShadow:
            'rgb(221,221,221) 0.5rem 0px 0px, rgb(221,221,221) -0.5rem 0px 0px',
          backgroundColor: 'rgb(221,221,221)',
          marginTop: "0px",
          color: 'white',
          lineHeight: '1',
          padding: '0.25em',
        }}
      >
        {pitchvideo.subheading2}
      </h3>
      <iframe className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen" id="ytplayer" type="text/html" width="640" height="360"
      src={"https://www.youtube.com/embed/" + pitchvideo.videoid}
       frameborder="0"></iframe>
      <DesignButton className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen"> Fræðsla </DesignButton>
    </div>
      </div>*/}
    <div ref={scrollRef}>
      <GreenhousePitch
        listitems={pitchlist.listitems}
        heading={pitchlist.heading}
        subheading={pitchlist.subheading}
      />
    </div>

    <div>
      <Grid justify="space-between" className="ideal-container" container>
        <Grid item xs={12} md={6}>
          <div
            className="pitch-image-content"
            style={{
              backgroundImage: `url(${assetFlip})`,
              backgroundPosition: `bottom left`,
              height: "835px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "750px",
            }}
          >
            <div className="content">
              <h2>Hugsjónin</h2>

              <div
                className="main-text"
                dangerouslySetInnerHTML={{ __html: pitchimage.content }}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="pitch-image-container">
            <div className="content">
              <img
                src={pitchimage.image.publicURL}
                className="pitch-image"
              ></img>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>

    <div className="content">
      <Grid justify="space-between" className="future-dream" container>
        <Grid item xs={12} md={6}>
          <div>
            <div
              className="image"
              style={{
                backgroundImage: `url(${
                  !!pitchcloser.headerimage.childImageSharp
                    ? pitchcloser.headerimage.childImageSharp.fluid.src
                    : pitchcloser.headerimage
                })`,
                backgroundPosition: `top center`,
                height: "610px",
              }}
            ></div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="content closer">
            <h2>{pitchcloser.heading}</h2>
            <div
              className="main-text"
              dangerouslySetInnerHTML={{ __html: pitchcloser.content }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subheading: PropTypes.string,
  pitchlist: PropTypes.object,
  pitchvideo: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const [selectedPitchListItem, setSelectedPitchListItem] = React.useState(0);
  const changeImage = (index) => {
    setSelectedPitchListItem(index);
    return;
  };
  const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView();
  return (
    <Layout frontPage>
      <IndexPageTemplate
        changeImage={changeImage}
        selectedPitchListItem={selectedPitchListItem}
        pitchcloser={frontmatter.pitchcloser}
        image={frontmatter.image}
        title={frontmatter.title}
        pitchvideo={frontmatter.pitchvideo}
        pitchimage={frontmatter.pitchimage}
        subheading={frontmatter.subheading}
        pitchlist={frontmatter.pitchlist}
        description={frontmatter.description}
        intro={frontmatter.intro}
        executeScroll={executeScroll}
        scrollRef={scrollRef}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subheading
        pitchvideo {
          heading
          subheading1
          subheading2
          videoid
        }
        pitchimage {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          heading
          content
        }
        pitchcloser {
          heading
          content
          headerimage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          sideimage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        pitchlist {
          listitems {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
            listtext
          }
          heading
          subheading
        }
      }
    }
  }
`;
